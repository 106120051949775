import Vue from 'vue';
import { Form, Field,Toast} from 'vant';
import { Button } from 'vant';
import  role from '../../assets/lib/role';
import { editPassword, getCode } from '../../api/registerApi';
let Base64 = require('js-base64').Base64;

Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);

export default {
  components: {},
  data() {
    return {
      userPhone: '',
      code: '',
      userPassword: '',
      rePassword: '',
      second: 60,
      isget: true,
      maxLength:"11",
      types:false,
      maxLength1:'6'
    };
  },
  created() {
    const typelist = this.$route.query.types;
    if(typelist==1){
      this.types=true
    }else{
      this.types=false
    }

  },
  methods: {
    back(){
      this.$router.go(-1);
    },
    onSubmit() {
      var flag = this.resVerification();
      if(flag){
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0,
        });
        var data=JSON.stringify({
          code:this.code,
          rePassword:Base64.encode(this.rePassword),
          userPassword:Base64.encode(this.userPassword),
          userPhone:this.userPhone,
          token:localStorage.getItem('token')
        })
        editPassword(data).then(res=>{
          Toast.clear()
          if(res.code==200){
            Toast({
              message: '修改成功！',
              forbidClick: true,
              onClose: () => {

                this.$router.push({
                  name: 'Login'
                });
              },
            });
          }else{
            Toast(res.msg)
          }
        })
      }

    },
    // 获取验证码
    getregVers: function() {
      const phone = this.userPhone;
      if (phone !== '') {
        if (!role.phone.test(phone)) {
          Toast('请输入正确手机号');
        } else {
          getCode({ phone }).then((res) => {

            if (res.code === 200) {
              Toast({
                message:'验证码已发送，请注意查收!'
              });
              this.isget = false;
              this.nogetVer()
            }else{
              Toast(res.msg)
            }
          });
        }
      } else {
        Toast('手机号不能为空');
      }
    },
      	// 获取验证码
		nogetVer: function() {
				var that = this
				this.setInnt = setInterval(function() {
					if (that.second > 1) {
						that.second -= 1
					} else {
						clearInterval(that.setInnt)
						that.isget = true;
						that.second = 60
					}

				}, 1000)


    },
       // 校验
      resVerification: function() {
      if (this.userPhone == "") {
        Toast('手机号不能为空！')
        return false;
      } else if (!role.phone.test(this.userPhone)) {
        Toast('手机号格式错误！')

        return false;
      } else if (this.code == "") {
        Toast('验证码不能为空！')

        return false;
      } else if (this.userPassword == "") {
        Toast('密码不能为空！')
        return false;
      } else if (!role.password.test(this.userPassword)) {
        Toast('密码长度应为8-16位且包含大小写字母、数字！')

        return false;
      } else if (this.rePassword == "") {
        Toast('请再次输入密码！')
        return false;
      } else if (this.userPassword != this.rePassword) {
        Toast('两次密码输入不一致！')
        return false;
      } else {
        return true;
      }
    },
  },
};
