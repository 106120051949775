import { render, staticRenderFns } from "./findPwd.vue?vue&type=template&id=dd5851ea&scoped=true&"
import script from "./findPwd.js?vue&type=script&lang=js&"
export * from "./findPwd.js?vue&type=script&lang=js&"
import style0 from "./findPwd.less?vue&type=style&index=0&id=dd5851ea&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd5851ea",
  null
  
)

export default component.exports